import React, { useEffect } from 'react'
import ServicesHero from '../../../Component/servicesHeroSection/ServicesHero'
import ServicesCard from '../../../Component/ServicesCard/ServicesCard'
import Utils from '../../../libs/Utils';

function CallCenterSer() {
  useEffect(() => {
    Utils.setPageTitle("Exceptional Call Center Services for Superior Customer Engagement by neXiate");
    Utils.setMetaTag("Enhance your customer experience with neXiate's exceptional call center services. Our dedicated team of skilled professionals provides seamless customer support, sales assistance, and multichannel communication to drive customer satisfaction and loyalty. Partner with us for reliable call center solutions that elevate your business's customer engagement and deliver outstanding results.")
  }, [])

  return (
    <>
    <div className='mobileAppDev_wrapper'>
    <ServicesHero text="Empowering Customer Engagement" />
    <div className="inner_sec_container">
      <div className="mobileAppDev_section mt-5">
      <h1>Deliver exceptional customer experiences with our comprehensive Call Center Services, offering professional support, multichannel communication, and enhanced customer satisfaction.</h1>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12 mb-5">
            <ServicesCard color="white" heading="Professional Customer Support" desc="Our call center services provide professional customer support to ensure exceptional experiences for your customers. Our skilled agents are trained to handle inquiries, resolve issues, and provide prompt solutions, leaving a positive impression of your brand." />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mb-5">
            <ServicesCard color="grey" heading="Multichannel Communication" desc="We offer multichannel communication options to cater to diverse customer preferences. Whether it's phone calls, email, live chat, or social media, we ensure seamless interactions across various channels, allowing customers to reach out to you through their preferred method." />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mb-5">
            <ServicesCard color="blue" heading="Enhanced Customer Satisfaction" desc="Our call center services are designed to enhance customer satisfaction by providing timely and efficient support. Through personalized interactions, quick problem resolution, and a customer-centric approach, we aim to leave your customers feeling valued and satisfied with your brand." />
          </div>
        </div>
        <div className="mobileAppDev_desc_area">
          <p>At neXiate, we provide professional call center services to help businesses deliver exceptional customer support and enhance overall customer experiences. With a dedicated team of trained professionals, state-of-the-art technology, and a customer-centric approach, we ensure that every interaction with your customers is positive and impactful. Discover how our call center services can benefit your business:</p>
          <h3>Inbound Call Services</h3>
          <p>Our skilled agents are ready to handle incoming calls from your customers. Whether it's answering inquiries, providing technical support, or addressing customer concerns, we strive to deliver prompt and efficient solutions. With a friendly and knowledgeable approach, we ensure that your customers receive the assistance they need, leaving a positive impression of your brand.</p>
          <h3>Outbound Call Services</h3>
          <p>Reach out to your customers proactively with our outbound call services. Whether it's conducting market research, generating leads, or following up with existing customers, our agents are skilled in engaging with your target audience. We aim to create meaningful connections, build relationships, and drive business growth through effective outbound calling campaigns.</p>
          <h3>Customer Relationship Management</h3>
          <p>We utilize advanced customer relationship management (CRM) tools to streamline customer interactions and provide personalized experiences. Our agents have access to comprehensive customer profiles, enabling them to understand customer preferences, history, and specific requirements. This allows for tailored interactions, improved problem resolution, and increased customer satisfaction.</p>
          <h3>Order Processing and Fulfillment</h3>
          <p>Efficiently manage your orders with our order processing and fulfillment services. Our agents are trained to handle order inquiries, process transactions, and ensure accurate and timely order fulfillment. With a seamless order management system in place, you can focus on core business activities while we take care of the logistics.</p>
          <h3>Help Desk Support</h3>
          <p>Provide reliable help desk support to your customers with our knowledgeable and friendly agents. We can assist with troubleshooting technical issues, guiding customers through product usage, and resolving common problems. Our goal is to ensure that your customers have a positive experience and feel supported throughout their journey with your products or services.</p>
          <h3>Multilingual Support</h3>
          <p>Expand your customer reach and cater to a global audience with our multilingual support services. We have a diverse team of agents proficient in various languages, enabling us to assist customers from different regions effectively. Whether it's providing support in English, Spanish, French, or any other language, we ensure clear communication and exceptional service.</p>
          <h3>24/7 Availability</h3>
          <p>We understand that customer support should be available around the clock. That's why we offer 24/7 availability to cater to your customers' needs at any time. With flexible shifts and a well-coordinated team, we can handle customer inquiries, provide assistance, and ensure that your customers always receive timely support.</p>
          <p>At neXiate, we are committed to delivering outstanding call center services that enhance customer satisfaction and drive business growth. Our team of dedicated professionals is trained to represent your brand with professionalism, empathy, and expertise. Contact us today to learn more about how our call center services can benefit your business.</p>
        </div>
      </div>
    </div>
  </div>
  </>
  )
}

export default CallCenterSer