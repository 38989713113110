import React from 'react';
import { FaQuoteLeft } from 'react-icons/fa';
import Classes from './BusinessModel.module.css';
import business_model_img from '../../images/business_model_img.png';
import { Link } from 'react-router-dom';

function BusinessModel() {
  return (
    <div className={Classes.our_business_model_section}>
            <div className="inner_sec_container">

              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className={Classes.our_business_model_left_section}>
                    <img src={business_model_img} alt="" />
                  </div>
                </div>
                <div className="col-lg-4 col-md-5 col-sm-12">
                  <div className={Classes.our_business_model_right_section}>
                    <h2>Simplicity at core</h2>
                    <p>
                    Our approach revolves around simplifying complex problems. From design to delivery, our approach helps in cost-effective solutions that can be adopted by the masses.
                    </p>
                    <div className={Classes.our_business_testimonal_area}>
                      <FaQuoteLeft className={Classes.quote_icon} />
                      <h3>Simplicity is about subtracting the obvious and adding the meaningful.</h3>
                      <p>JOHN MAEDA</p>
                    </div>
                    <button className={Classes.products_btn}>
                    <Link to="/about-us">About Us</Link>
                    </button>
                    {/* <button className={Classes.contactus_btn}>
                      <Link to="/contactus">Contact us</Link>
                    </button> */}
                  </div>
                </div>
              </div>

            </div>
          </div>
  )
}

export default BusinessModel