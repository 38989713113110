import React, { useEffect, useState } from 'react'
import Classes from './Header.module.css';
import logo from '../../images/logo.svg';
import { AiOutlineRight } from 'react-icons/ai';
import { RxCross1, RxHamburgerMenu } from 'react-icons/rx';
import { Link, useLocation } from 'react-router-dom';


function Header() {

    const location = useLocation();
    const [isSticky, setIsSticky] = useState(false);
    const [mobileMenu , setMobileMenu] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [])

    function handleLinkClick() {
        setMobileMenu(false);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    function handleScroll() {
        if (window.pageYOffset > 100) {
            setIsSticky(true);
        }
        else {
            setIsSticky(false);
        }
    }
    return (
        <header>
            <div className={`${Classes.header_wrapper} ${isSticky ? Classes.sticky : ''}`}>
                <div className={Classes.header_section}>
                    <div className="row row-cols-3" style={{ margin: '0' }}>
                        <div className="col-lg-3 col-md-2">
                            <div className={Classes.header_logo_section}>
                            <Link to="/" className={location.pathname === '/' ? `${Classes.active}` : ''} onClick={handleLinkClick}>
                                <img src={logo} alt="Nexiate logo" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-7">
                            <div className={Classes.header_nav_section}>
                                <div className={Classes.header_nav_area}>
                                    <ul>
                                        <li>
                                            <Link to="/" className={location.pathname === '/' ? `${Classes.active}` : ''} onClick={handleLinkClick}>Home</Link>
                                        </li>
                                        <li>
                                            <Link to="/about-us" className={location.pathname === '/about-us' ? `${Classes.active}` : ''} onClick={handleLinkClick}>About us</Link>
                                        </li>
                                        <li>
                                            <Link to="/services" className={location.pathname === '/services' ? `${Classes.active}` : ''} onClick={handleLinkClick}>Services</Link>
                                        </li>
                                        <li>
                                            <Link to="/contact-us" className={location.pathname === '/contact-us' ? `${Classes.active}` : ''} onClick={handleLinkClick}>Contact</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-sm-6 col-md-3">
                            <div className={Classes.mobile_menu_hamburger_section}>
                            <RxHamburgerMenu className={Classes.hamburger_icon} onClick={() => {setMobileMenu(true)}}/>
                            </div>
                          
                        </div>
                    </div>


                </div>


                {
                    mobileMenu &&  <div className={Classes.mobile_menu_section}>
                    <div className={`${Classes.mobile_menu_layer_section} ${mobileMenu ? Classes.layer_show : ''}`}></div>
                    <div className={Classes.mobile_menu_nav_section}>
                        <RxCross1 className={Classes.cross_icon} onClick={() => setMobileMenu(false)}/>
                        <div className={Classes.mobile_menu_nav_area}>
                            <ul>
                                <li>
                                    <Link to="/" className={location.pathname === '/' ? `${Classes.active}` : ''} onClick={handleLinkClick}  >Home</Link>
                                </li>
                                <li>
                                    <Link to="/about-us" className={location.pathname === '/about-us' ? `${Classes.active}` : ''} onClick={handleLinkClick}>About us</Link>
                                </li>
                                <li>
                                    <Link to="/services" className={location.pathname === '/services' ? `${Classes.active}` : ''} onClick={handleLinkClick}>Services</Link>
                                </li>
                                <li>
                                    <Link to="/contact-us" className={location.pathname === '/contact-us' ? `${Classes.active}` : ''} onClick={handleLinkClick}>Contact</Link>
                                </li>
                            </ul>
                        </div>

                    </div>

                </div>
                }
              
            </div>
        </header>
    )
}

export default Header