import React from 'react'
import Classes from './ServicesCard.module.css'

function ServicesCard(props) {
    return (
        <div className={Classes.services_card_section} style={{ backgroundColor: props.color === 'black' ? '#00000' : props.color === 'white' ? '#ffff' : props.color === 'blue' ? '#2029BD' : props.color === 'grey' ? '#E7E9EE' : '#000', border: props.color === 'white' ? '1px solid #E7E9EE' : 'none' }}>
            <div className={Classes.services_card_content_area}>
                <h2 style={{ color: props.color === 'grey' ? '#000' : props.color === 'white' ? '#000' : '#fff' }}>{props.heading}</h2>
                <p style={{ color: props.color === 'grey' ? '#747880' : props.color === 'white' ? '#747880' : '#fff' }}>{props.desc}</p>
            </div>
        </div>
    )
}

export default ServicesCard