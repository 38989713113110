import React, { useEffect, useState } from 'react';
import './ContactUs.css'
import contact_hero_img from '../../images/contactUs-hero-img.png'
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa';
import Utils from '../../libs/Utils';

function ContactUs() {
    useEffect(() => {
        Utils.setPageTitle("Get in Touch: Contact neXiate for Exceptional Digital Solutions");
        Utils.setMetaTag("Contact neXiate today to discuss your digital needs and discover how our expert team can help you achieve your business goals. Whether you're looking for custom app development, cloud integration, or ongoing support, we're here to provide exceptional solutions and personalized assistance.")
      }, [])

    const [f_name, setFirstName] = useState('');
    const [l_name, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const handleSubmit = (event) => {
        event.preventDefault();
      
        fetch('send_email.php', {
          method: 'POST',
          body: new FormData(event.target)
        })
          .then((response) => response.text())
          .then((result) => {
            //console.log(result);
            setSuccessMessage('Thank you for your message. We have received your request successfully and will contact you within a few hours.');
          })
          .catch((error) => {
            //console.error(error);
          });
      };
    return (
        <>
            <div className="contact_us_wrapper">
                <div className="small_hero_section">


                    <div className="small_hero_right_section">
                        <img src={contact_hero_img} alt="" />
                    </div>
                    <div className="small_hero_left_section">
                        <div className="small_hero_left_content_section">
                            <h1>Feel free to contact us</h1>
                        </div>
                    </div>
                </div>
                <div className="inner_sec_container">
                    <div className="contact_us_content_section">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="contact_us_content_area mt-5">
                                    <div className="contact_us_info_section">
                                        <h2>Address</h2>
                                        <p>Sydney CBD, 2000,Australia</p>
                                        <p>Phone <a href='tel:+61481286345'>+61 (481) 286 345</a></p>
                                    </div>
                                    <div className="contact_us_social_icons">
                                        <h2>Social Media</h2>
                                        <div className="contact_us_social_icons_area mt-3">
                                        <a href='https://www.facebook.com/neXiate' target='_blank'><FaFacebookF className="social_icon" /></a>
                                        <a href='https://linkedin.com/company/nexiate' target='_blank'><FaLinkedinIn className="social_icon linkedIn" /></a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-8">
                                <div className="contact_us_form_section mt-5">
                                    <div className="contact_us_form_area">
                                        <h2>Contact us</h2>
                                        <form onSubmit={handleSubmit} action="send_email.php">
                                        {successMessage && <p className="success">{successMessage}</p>}
                                            <div className="row mt-4">
                                                <div className="col-lg-6 ps-0 mb-3">
                                                    <label htmlFor="">First Name <span style={{ color: 'red' }}>*</span></label> <br />
                                                    <input name="f_name" type="text" value={f_name} required onChange={(event) => setFirstName(event.target.value)} />
                                                </div>
                                                <div className="col-lg-6 ps-0 mb-3">
                                                    <label htmlFor="">Last Name</label> <br />
                                                    <input  name="l_name" type="text" value={l_name} onChange={(event) => setLastName(event.target.value)} />
                                                </div>
                                                <div className="col-lg-6 ps-0 mb-3">
                                                    <label htmlFor="">Email <span style={{ color: 'red' }}>*</span></label> <br />
                                                    <input  name="email" type="email" value={email} required onChange={(event) => setEmail(event.target.value)} />
                                                </div>
                                                <div className="col-lg-6 ps-0 mb-3">
                                                    <label htmlFor="">Phone Number</label> <br />
                                                    <input name="phone" type="text" value={phone} onChange={(event) => setPhone(event.target.value)} />
                                                </div>
                                                <div className="col-lg-12 ps-0 mb-3">
                                                    <label htmlFor="">Message <span style={{ color: 'red' }}>*</span></label> <br />
                                                    <textarea name="message" id="" cols="30" rows="10" required value={message} onChange={(event) => setMessage(event.target.value)}></textarea>
                                                </div>
                                                <div className="col-lg-12 ps-0 mb-3">
                                                    <button type="submit" className='submit_btn'>Submit</button>
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactUs