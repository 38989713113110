import React, { useEffect } from 'react'
import ServicesHero from '../../../Component/servicesHeroSection/ServicesHero'
import ServicesCard from '../../../Component/ServicesCard/ServicesCard'
import Utils from '../../../libs/Utils';

function MaintenceSupport() {
  useEffect(() => {
    Utils.setPageTitle("Reliable Support for Your Digital Solutions: Maintenance and Support by neXiate");
    Utils.setMetaTag("Ensure the smooth operation of your digital solutions with neXiate's comprehensive maintenance and support services. Our dedicated team provides timely updates, bug fixes, and performance optimizations to keep your applications running at their best. With our reliable support, you can focus on your core business while we handle the technical aspects of your digital infrastructure.")
  }, [])
  return (
    <>
    <div className='mobileAppDev_wrapper'>
    <ServicesHero text="Ensuring Smooth Operation" />
    <div className="inner_sec_container">
      <div className="mobileAppDev_section mt-5">
      <h1>By focusing on regular updates and bug fixes, providing reliable technical support, and monitoring and optimizing system performance, businesses can ensure that their software or systems remain functional, secure, and efficient. Maintenance and support services play a vital role in maximizing the value of technology investments, minimizing disruptions, and enabling businesses to focus on their core operations.</h1>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12 mb-5">
            <ServicesCard color="black" heading="Regular Updates and Bug Fixes" desc="Maintenance and support involve ensuring that the software or system is up-to-date and free from bugs and vulnerabilities. Regular updates are essential to incorporate new features, security patches, and performance enhancements. By addressing bugs and issues promptly, businesses can ensure the smooth functioning of their software or system, improve user experience, and maintain a secure environment." />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mb-5">
            <ServicesCard color="grey" heading="Technical Support" desc="Providing reliable technical support is crucial in addressing user queries, resolving issues, and minimizing downtime. A dedicated support team should be available to assist users, answer their questions, and troubleshoot any technical problems they encounter. Prompt and efficient technical support enhances user satisfaction, builds customer loyalty, and contributes to overall customer success." />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mb-5">
            <ServicesCard color="black" heading="System Performance Monitoring" desc="Continuous monitoring and optimization of system performance are key to maintaining optimal functionality and user experience. Proactive monitoring allows for early detection of performance bottlenecks, scalability issues, or resource limitations. Regular performance assessments help identify areas for improvement, such as optimizing code, database tuning, or infrastructure adjustments, to ensure the system operates at peak efficiency." />
          </div>
        </div>
        <div className="mobileAppDev_desc_area">
          <p>At Nexiate, we understand the importance of ongoing maintenance and support to ensure the smooth operation and longevity of your software or system. Our comprehensive maintenance and support services are designed to address issues promptly, provide technical assistance, and optimize performance, allowing you to focus on your core business activities.</p>
          <h3>Regular Updates and Bug Fixes</h3>
          <p>We prioritize regular updates and bug fixes to keep your software or system up-to-date and functioning at its best. Our team of experienced professionals stays updated with the latest technologies, security patches, and industry best practices to ensure that your software remains secure, compatible, and optimized for performance. By addressing bugs and vulnerabilities promptly, we help maintain a stable and reliable environment for your users.</p>
          <h3>Technical Support and Troubleshooting</h3>
          <p>We provide reliable technical support to address any queries or issues that may arise. Our dedicated support team is available to assist users, answer their questions, and provide timely resolutions to any technical challenges they may encounter. With our responsive and knowledgeable support, you can ensure a positive user experience, minimize downtime, and keep your users satisfied and productive.</p>
          <h3>System Performance Monitoring and Optimization</h3>
          <p>We understand the importance of system performance in delivering a seamless user experience. Our experts continuously monitor your software or system to proactively identify any performance bottlenecks, scalability concerns, or resource limitations. By analyzing system metrics, optimizing code, fine-tuning databases, and making infrastructure adjustments, we ensure that your software or system operates at peak performance, providing a smooth and efficient user experience.</p>
          <p>Partner with us for comprehensive maintenance and support services that keep your software or system running smoothly and reliably. Our team is dedicated to providing timely updates, addressing bugs and issues, delivering responsive technical support, and optimizing performance. With our maintenance and support services, you can maximize the value of your technology investments, minimize disruptions, and stay ahead in today's rapidly evolving digital landscape. Let us take care of your maintenance and support needs while you focus on growing your business.</p>
        </div>
      </div>
    </div>
  </div>
  </>
  )
}

export default MaintenceSupport