import './HomePage.css';
import { BsCheck } from 'react-icons/bs';
import hero_img from '../../images/hero_img.png';
import hero_mobile_img from '../../images/homePage_hero_mobile.png';
import three_pilors_img1 from '../../images/user-centric-approach.svg';
import three_pilors_img2 from '../../images/business-alignment.svg';
import header_video from '../../images/video.mp4';
import header_video2 from '../../images/video2.mp4';
import header_video3 from '../../images/video3.mp4';
import three_pilors_img3 from '../../images/scalability-flexibility.svg';
import BusinessModel from '../../Component/BusinessModel/BusinessModel';
import { Link, useNavigate } from 'react-router-dom';
import Quote_Now_img from '../../images/quote_now_img.png'

// import { Helmet } from 'react-helmet';
import Utils from '../../libs/Utils';
import { useRef, useEffect } from 'react';
function HomePage() {

  const history = useNavigate();
  const gotoQuote = () => {
    history('/contact-us');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  function handleLinkClick() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  useEffect(() => {
    Utils.setPageTitle("Empowering Your Success: neXiate - Your Partner in Digital Solutions");
    Utils.setMetaTag("Unlock your business's full potential with neXiate , a leading provider of innovative digital solutions. From web and mobile app development to cloud integration and maintenance, our expert team is committed to delivering exceptional results tailored to your unique needs.")
  }, [])


  const videoRef = useRef(null);
  const videos = [
    { src: header_video3, type: 'video/mp4' },
    { src: header_video, type: 'video/mp4' },
    { src: header_video2, type: 'video/mp4' }
  ];
  const currentVideoIndex = useRef(0);
  useEffect(() => {
    playNextVideo();
  }, []);

  const playNextVideo = () => {
    const currentIndex = currentVideoIndex.current;
    const nextIndex = (currentIndex + 1) % videos.length;

    if (videoRef.current && !videoRef.current.paused) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }

    currentVideoIndex.current = nextIndex;
    videoRef.current.src = videos[nextIndex].src;
    videoRef.current.load();
    videoRef.current.play();
  };

  return (
    <>
      {/* <Helmet>
    <meta name={pageTitle} content={pageDescription}/>
    </Helmet> */}
      <div className="home_wrapper">
        <div className="home_section">
          <div className="hero_section video-container">

            <video
              ref={videoRef}
              onEnded={playNextVideo}
              autoPlay
              muted
              playsInline
              data-keepplaying
              id="video-34286-1"
              width="100%"
              height="auto"
            >
              <source src={videos[currentVideoIndex.current].src} type={videos[currentVideoIndex.current].type} />
            </video>
        
            <div className="hero_left_section">
              <div className="hero_left_content_section">
                <h1>Initiate your next project with next generation solutions.</h1>
                <p>Transforming technologies to create bespoke solutions.</p>
                <div className="hero_section_btn_section">
                  <Link to="/services" className='who_we_are_btn' onClick={handleLinkClick}>What do we offer ?</Link>
                  <Link to="/contact-us" className='our_solution_btn' onClick={handleLinkClick}>Contact Us</Link>
                </div>

              </div>
            </div>


          </div>

          <div className="home_page_content_section">
            <BusinessModel />
            <div className="our_strategy_section" >
              <div className="inner_sec_container">
                <div className="our_strategy_content_section">
                  <h2>The Three Pillars</h2>
                  <div className="our_products_section">
                    <div className="row">
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="our_product_cards_section">
                          <div className="our_product_img_section">
                            <img src={three_pilors_img1} alt="" />
                          </div>
                          <div className='our_product_cards-area'>
                            <h2>User-Centric Approach</h2>
                            <div className="our_product_features_section">
                              <div className='our_fetaure_content_section'>
                                <div className="our_product_features_area">
                                  <BsCheck className='check_icon' />
                                  <span>Prioritize user needs</span>
                                </div>
                                <div className="our_product_features_area">
                                  <BsCheck className='check_icon' />
                                  <span>Understand the target audience</span>
                                </div>
                                <div className="our_product_features_area">
                                  <BsCheck className='check_icon' />
                                  <span>
                                    User research insights</span>
                                </div>
                                <div className="our_product_features_area">
                                  <BsCheck className='check_icon' />
                                  <span>User feedback incorporation</span>
                                </div>
                                <div className="our_product_features_area">
                                  <BsCheck className='check_icon' />
                                  <span>Meeting user requirements</span>
                                </div>
                              </div>
                            </div>
                          </div>


                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="our_product_cards_section">
                          <div className="our_product_img_section">
                            <img src={three_pilors_img2} alt="" />
                          </div>
                          <div className='our_product_cards-area'>
                            <h2>Business Alignment</h2>
                            <div className="our_product_features_section">
                              <div className='our_fetaure_content_section'>
                                <div className="our_product_features_area">
                                  <BsCheck className='check_icon' />
                                  <span>Functionality alignment</span>
                                </div>
                                <div className="our_product_features_area">
                                  <BsCheck className='check_icon' />
                                  <span>Business process understanding</span>
                                </div>
                                <div className="our_product_features_area">
                                  <BsCheck className='check_icon' />
                                  <span>
                                    Operations streamlining</span>
                                </div>
                                <div className="our_product_features_area">
                                  <BsCheck className='check_icon' />
                                  <span>Objective contribution</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="our_product_cards_section">
                          <div className="our_product_img_section">
                            <img src={three_pilors_img3} alt="" />
                          </div>
                          <div className='our_product_cards-area'>
                            <h2>Scalability and Flexibility</h2>
                            <div className="our_product_features_section">
                              <div className='our_fetaure_content_section'>
                                <div className="our_product_features_area">
                                  <BsCheck className='check_icon' />
                                  <span>Scalable and flexible design</span>
                                </div>
                                <div className="our_product_features_area">
                                  <BsCheck className='check_icon' />
                                  <span>Performance scalability</span>
                                </div>
                                <div className="our_product_features_area">
                                  <BsCheck className='check_icon' />
                                  <span>
                                    Adaptive flexibility</span>
                                </div>
                                <div className="our_product_features_area">
                                  <BsCheck className='check_icon' />
                                  <span>Growth-oriented architecture</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="what_we_can_do_section">
              <div className="inner_sec_container">
                <div className="what_we_can_do_area">
                  <h3>What we can do for you?</h3>
                  <div className="services_card_section">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                        <Link to="/mobile-app-development" onClick={handleLinkClick}>
                          <div className="services_card_area">
                            <div className="services_card_content_section">
                              <div className="services_card_content_area">
                                <h3>Mobile App Development</h3>
                                <p>Create tailored mobile applications for iOS and Android platforms, designed to meet specific client requirements and deliver seamless user experiences.</p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                        <Link to="/responsive-website" onClick={handleLinkClick}>
                          <div className="services_card_area">
                            <div className="services_card_content_section">
                              <div className="services_card_content_area">
                                <h3>Responsive Web Development</h3>
                                <p>Develop responsive websites and web pplications that adapt to different screen sizes and devices, ensuring optimal user experience across desktop, mobile, and tablet platforms.</p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                        <Link to="/ecommerce-solution" onClick={handleLinkClick}>
                          <div className="services_card_area">
                            <div className="services_card_content_section">
                              <div className="services_card_content_area">
                                <h3>E-commerce Solutions</h3>
                                <p>Build secure and user-friendly e-commerce platforms for clients to establish their online stores, integrating payment gateways, inventory management, and customer engagement features.</p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                        <Link to="/cross-platform-development" onClick={handleLinkClick}>
                          <div className="services_card_area">
                            <div className="services_card_content_section">
                              <div className="services_card_content_area">
                                <h3>Cross-Platform Development</h3>
                                <p>Offer cross-platform development services using frameworks like React Native or Flutter, allowing clients to reach a wider audience with a single codebase.</p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                        <Link to="/web-mobile-ui-ux-design" onClick={handleLinkClick}>
                          <div className="services_card_area">
                            <div className="services_card_content_section">
                              <div className="services_card_content_area">
                                <h3>Web and Mobile UI/UX Design</h3>
                                <p>Create visually appealing and intuitive user interfaces (UI) and deliver exceptional user experiences (UX) for web and mobile applications.</p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                        <Link to="/cloud-integration-and-migration" onClick={handleLinkClick}>
                          <div className="services_card_area">
                            <div className="services_card_content_section">
                              <div className="services_card_content_area">
                                <h3>Cloud Integration and Migration</h3>
                                <p>Help clients leverage the benefits of cloud computing by integrating their applications with leading cloud platforms such as AWS, Azure, or Google Cloud, and facilitate seamless migration to the cloud.</p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                        <Link to="/maintenance-and-support" onClick={handleLinkClick}>
                          <div className="services_card_area">
                            <div className="services_card_content_section">
                              <div className="services_card_content_area">
                                <h3>Maintenance and Support</h3>
                                <p>Offer ongoing maintenance and support services to ensure the smooth operation, performance optimization, and timely updates of the developed applications.</p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                        <Link to="/dedicated-resource-teams" onClick={handleLinkClick}>
                          <div className="services_card_area">
                            <div className="services_card_content_section">
                              <div className="services_card_content_area">
                                <h3>Dedicated Resource Teams</h3>
                                <p>Provide dedicated resources and teams to clients, working full-time in their respective time zones. This ensures real-time collaboration, effective communication, and seamless coordination between your team and the client, resulting in efficient project management and faster time-to-market.</p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                        <Link to="/call-center-services" onClick={handleLinkClick}>
                          <div className="services_card_area">
                            <div className="services_card_content_section">
                              <div className="services_card_content_area">
                                <h3>Call Center Services</h3>
                                <p>Deliver exceptional customer support and enhance customer experiences with our call center services. Our trained professionals handle inbound and outbound calls, providing personalized assistance and ensuring customer satisfaction.</p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                        <Link to="/additional-services" onClick={handleLinkClick}>
                          <div className="services_card_area">
                            <div className="services_card_content_section">
                              <div className="services_card_content_area">
                                <h3>Additional Services</h3>
                                <p>In addition to our core services, we also offer a range of supplementary services such as content writing, digital marketing, SEO, and more. Our team of experts can help you create compelling content, improve your online visibility, and drive targeted traffic to your website.</p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>

                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="quote_now_section">
              <div className="quote_now_img_section">
                <img src={Quote_Now_img} alt="" />
              </div>
              <div className="quote_now_content_section">
                <div className="quote_now_content_area">
                  <h3>Request Your Free Quote Now!</h3>
                  <p>Unlock the potential of your business with a free quote tailored just for you. Click below to get started and let our experts understand your needs. Take the first step towards success today</p>
                  <div className="quote_now_button_section">
                    <button className='get_quote_btn' onClick={gotoQuote}>Get Quote</button>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default HomePage