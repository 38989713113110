import React, { useEffect } from 'react'
import ServicesHero from '../../../Component/servicesHeroSection/ServicesHero'
import ServicesCard from '../../../Component/ServicesCard/ServicesCard'
import Utils from '../../../libs/Utils';

function ResponsiveWebsite() {
  useEffect(() => {
    Utils.setPageTitle("Seamless User Experiences: Responsive Web Development by neXiate");
    Utils.setMetaTag("Enhance your online presence with responsive web development services from neXiate. Our team leverages the latest technologies to create stunning and user-friendly websites that adapt seamlessly across devices. From desktop to mobile, we ensure your website delivers exceptional user experiences and drives conversions.")
  }, [])

  return (
    <>
    <div className='mobileAppDev_wrapper'>
    <ServicesHero text="Crafting Engaging and Adaptable Websites" />
    <div className="inner_sec_container">
      <div className="mobileAppDev_section mt-5">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12 mb-5">
            <ServicesCard color="white" heading="Fluid and Adaptive Design" desc="Creating a responsive website involves designing and developing layouts that fluidly adapt to different screen sizes and devices. This ensures that the website maintains its visual appeal and usability across desktops, tablets, and mobile devices." />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mb-5">
            <ServicesCard color="grey" heading="Mobile-First Approach" desc="Prioritizing mobile devices and designing websites with a mobile-first approach is crucial. This involves considering the unique constraints and requirements of mobile users, optimizing performance, and delivering a seamless user experience on smaller screens." />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mb-5">
            <ServicesCard color="white" heading="Media Queries and Flexible Grids" desc="Implementing media queries and utilizing flexible grids enables the website to respond to different screen sizes and orientations. This allows content and design elements to dynamically adjust, ensuring optimal visibility and usability for users across a range of devices." />
          </div>
        </div>
        <div className="mobileAppDev_desc_area">
          <p>At neXiate, we specialize in responsive website development, crafting dynamic and visually appealing websites that seamlessly adapt to various screen sizes and devices. With the ever-increasing use of mobile devices, it has become essential for businesses to provide an optimal browsing experience for their users across desktops, tablets, and smartphones.</p>
         <p>Our expert team of web developers follows industry best practices to ensure that your website is responsive and user-friendly. We employ a mobile-first approach, designing and developing websites with the primary focus on mobile devices. This approach allows us to prioritize content, performance, and usability for mobile users while ensuring a consistent and engaging experience across all devices.</p>
          <p>Using advanced techniques such as fluid grids, flexible layouts, and media queries, we create websites that automatically adjust and optimize their layout, images, and typography based on the device being used. This results in a seamless and visually appealing user experience, regardless of the screen size or orientation.</p>
         <p>Responsive websites offer several benefits, including increased reach, improved user engagement, and better search engine visibility. With a responsive website, your business can effectively target and engage users on different devices, ultimately driving higher conversion rates and customer satisfaction.</p>
        <p>We understand that every business has unique requirements and objectives. That's why our responsive website development process is tailored to meet your specific needs. Our team collaborates closely with you to understand your brand, target audience, and business goals, ensuring that the website reflects your identity and delivers a compelling user experience.</p>
        <p>Partner with us for responsive website development that not only meets modern web standards but also provides your users with a seamless browsing experience across devices. With our expertise, we will help your business make a lasting impression and achieve its online objectives in the rapidly evolving digital landscape.</p>
        </div>
      </div>
    </div>
  </div>
  </>
  )
}

export default ResponsiveWebsite