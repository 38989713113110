import { Route, Routes } from 'react-router-dom';
import './App.css';
import Footer from './Component/Footer/Footer';
import Header from './Component/Header/Header';
import AboutUs from './pages/AboutUs/AboutUs';
import ContactUs from './pages/ContactUs/ContactUs';
import HomePage from './pages/HomePage/HomePage';
import Services from './pages/Services/Services';
import MobileAppDev from './pages/Services/MobileAppDev/MobileAppDev';
import ResponsiveWebsite from './pages/Services/MobileAppDev/ResponsiveWebsite';
import EcommerceSol from './pages/Services/MobileAppDev/EcommerceSol';
import CrossPlatformDev from './pages/Services/MobileAppDev/CrossPlatformDev';
import WebMobDesign from './pages/Services/MobileAppDev/WebMobDesign';
import CloudIntegration from './pages/Services/MobileAppDev/CloudIntegration';
import MaintenceSupport from './pages/Services/MobileAppDev/MaintenceSupport';
import DedicatedResTeam from './pages/Services/MobileAppDev/DedicatedResTeam';
import CallCenterSer from './pages/Services/MobileAppDev/CallCenterSer';
import AdditionalSer from './pages/Services/MobileAppDev/AdditionalSer';

function App() {
  
  return (
    <>
      
      <Header />
      <Routes>
        <Route exact path='/' element={<HomePage />} />
        <Route exact path='/about-us' element={<AboutUs />} />
        <Route exact path='/services' element={<Services />} />
        <Route exact path='/contact-us' element={<ContactUs />} />
        <Route exact path='/mobile-app-development' element={<MobileAppDev />} />
        <Route exact path='/responsive-website' element={<ResponsiveWebsite />} />
        <Route exact path='/ecommerce-solution' element={<EcommerceSol />} />
        <Route exact path='/cross-platform-development' element={<CrossPlatformDev />} />
        <Route exact path='/web-mobile-ui-ux-design' element={<WebMobDesign />} />
        <Route exact path='/cloud-integration-and-migration' element={<CloudIntegration />} />
        <Route exact path='/maintenance-and-support' element={<MaintenceSupport />} />
        <Route exact path='/dedicated-resource-teams' element={<DedicatedResTeam />} />
        <Route exact path='/call-center-services' element={<CallCenterSer />} />
        <Route exact path='/additional-services' element={<AdditionalSer />} />

      </Routes>

      <Footer />
    </>
  );
}

export default App;
