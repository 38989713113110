import React, { useEffect } from 'react'
import ServicesHero from '../../../Component/servicesHeroSection/ServicesHero'
import ServicesCard from '../../../Component/ServicesCard/ServicesCard'
import Utils from '../../../libs/Utils';

function AdditionalSer() {
  useEffect(() => {
    Utils.setPageTitle("Comprehensive Additional Services to Accelerate Your Digital Success with neXiate");
    Utils.setMetaTag("Unlock the full potential of your digital presence with neXiate's comprehensive additional services. From content creation and marketing expertise to SEO strategies and support, our range of services complements your core digital solutions. Partner with us to amplify your online impact and achieve exceptional results across your digital channels.")
  }, [])
  return (
    <>
    <div className='mobileAppDev_wrapper'>
    <ServicesHero text="Expanding Your Possibilities" />
    <div className="inner_sec_container">
      <div className="mobileAppDev_section mt-5">
      <h1>Discover our range of additional services designed to complement and enhance our core solutions, providing you with the extra support and expertise needed to propel your business forward.</h1>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12 mb-5">
            <ServicesCard color="white" heading="Content Creation and Copywriting" desc="Our expert team of content writers and copywriters can create compelling and engaging content for your website, blog, social media platforms, and marketing materials. With their expertise in crafting persuasive copy and attention-grabbing content, we help you effectively communicate your brand message, connect with your target audience, and drive engagement." />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mb-5">
            <ServicesCard color="grey" heading="Search Engine Optimization (SEO)" desc="Boost your online visibility and improve your website's ranking on search engine results pages with our SEO services. Our skilled SEO professionals will optimize your website's structure, content, and keywords, ensuring that it is easily discoverable by search engines and relevant to user queries. By increasing organic traffic and improving your search engine rankings, we help you attract more qualified leads and grow your online presence." />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mb-5">
            <ServicesCard color="blue" heading="Digital Marketing Strategy" desc="Take your digital marketing efforts to the next level with our strategic digital marketing services. Our team of digital marketing specialists will work closely with you to develop tailored strategies that align with your business goals. Whether it's social media marketing, email campaigns, paid advertising, or content promotion, we leverage industry best practices and data-driven insights to drive targeted traffic, generate leads, and achieve measurable results." />
          </div>
        </div>
        <div className="mobileAppDev_desc_area">
          <p>At neXiate, we go beyond the standard offerings to provide a range of additional services that complement and enhance our core solutions. With our expertise and dedication to client success, we strive to meet all your unique requirements. Explore our additional services and discover how they can benefit your business:</p>
          <h3>Content Creation and Copywriting</h3>
          <p>Our team of skilled content writers is ready to craft engaging and persuasive content for your website, blog, social media, and marketing materials. From captivating articles to compelling product descriptions, we ensure that your message resonates with your target audience, helping you to build brand awareness and drive customer engagement.</p>
          <h3>Search Engine Optimization (SEO)</h3>
          <p>Increase your online visibility and drive organic traffic to your website with our search engine optimization (SEO) services. Our SEO experts will optimize your website's structure, content, and keywords to improve its ranking on search engine result pages. By implementing effective SEO strategies, we help you attract more qualified leads and boost your online presence.</p>
          <h3>Digital Marketing</h3>
          <p>Accelerate your digital marketing efforts with our comprehensive range of services. From social media management and paid advertising campaigns to email marketing and content promotion, our digital marketing specialists will develop and execute strategies tailored to your business objectives. Maximize your online reach and generate measurable results with our targeted digital marketing solutions.</p>
          <h3>Conversion Rate Optimization (CRO)</h3>
          <p>Unlock the full potential of your website by optimizing its conversion rate. Our CRO specialists analyze user behavior, conduct A/B testing, and make data-driven improvements to your website's design and functionality. By enhancing user experience and streamlining the conversion funnel, we help you maximize conversions and achieve your business goals.</p>
          <h3>Analytics and Reporting</h3>
          <p>Gain valuable insights into your online performance with our analytics and reporting services. We provide detailed reports and data analysis, allowing you to monitor key metrics, track campaign effectiveness, and make informed business decisions. With our comprehensive analytics solutions, you can measure your success and identify areas for improvement.</p>
          <h3>Project Management</h3>
          <p>Ensure the smooth execution of your projects with our professional project management services. Our experienced project managers will oversee every aspect of your project, from planning and resource allocation to monitoring progress and ensuring timely delivery. By leveraging industry best practices and efficient project management methodologies, we help you achieve your project goals effectively.</p>
          <p>At neXiate, we are committed to providing holistic solutions that address all your business needs. Our additional services are designed to complement our core offerings and empower your business growth. Contact us today to learn more about how our additional services can elevate your brand and drive success.</p>
        </div>
      </div>
    </div>
  </div>
  </>
  )
}

export default AdditionalSer