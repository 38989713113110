import React, { useEffect } from 'react'
import './MobileAppDev.css'
import ServicesHero from '../../../Component/servicesHeroSection/ServicesHero';
import ServicesCard from '../../../Component/ServicesCard/ServicesCard';
import Utils from '../../../libs/Utils';


function MobileAppDev() {
  useEffect(() => {
    Utils.setPageTitle("Powerful Mobile Solutions: Expert Mobile Development by neXiate");
    Utils.setMetaTag("Unlock the full potential of mobile technology with neXiate's expert mobile development services. Our skilled team crafts powerful and user-friendly mobile apps tailored to your specific requirements. From concept to launch, we deliver innovative solutions that engage users and drive business growth.")
  }, [])
  return (
    <>
    <div className='mobileAppDev_wrapper'>
      <ServicesHero text="Building World Class Mobile Apps" />
      <div className="inner_sec_container">
        <div className="mobileAppDev_section mt-5">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 mb-5">
              <ServicesCard color="black" heading="User Experience (UX)" desc="Prioritizing a seamless and intuitive user experience is crucial. This includes designing a user-friendly interface, ensuring smooth navigation, and optimizing  performance for a responsive and engaging mobile app." />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-5">
              <ServicesCard color="grey" heading="Functionality and Features" desc="Identifying and implementing the essential functionalities and features that align with the app's purpose and target audience is vital.This involves thorough planning and understanding of user requirements to deliver a valuable and effective mobile app solution." />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-5">
              <ServicesCard color="blue" heading="Platform Compatibility" desc="Considering the different mobile platforms (such as iOS and Android) and ensuring compatibility across devices and operating systems is important. Adapting the app's design and development to each platform's guidelines and best practices ensures a consistent experience for users across various devices and operating systems." />
            </div>
          </div>
          <div className="mobileAppDev_desc_area">
            <p>At neXiate, we specialize in delivering tailor-made mobile app solutions that cater to the unique needs of your business. Our expert team of
              developers works closely with you to understand your requirements, goals, and target audience, ensuring that every aspect of the app aligns perfectly with your vision.</p>

           <p>With our custom mobile app development services, we bring your ideas to life by crafting engaging, intuitive, and feature-rich applications.
            Whether you need an app for iOS, Android, or cross-platform compatibility, our experienced developers leverage the latest technologies
            and best practices to build high-performance, user-friendly mobile experiences.</p>

            <p>From conceptualization and design to development and deployment, we guide you through every step of the process, ensuring a seamless and efficient
            development journey. We prioritize clean code, scalability, and security to create robust and future-proof mobile apps that can adapt and grow with your business.</p>

            <p>With our focus on customization, we ensure that your mobile app stands out in the competitive market. We integrate essential functionalities, such as user
            authentication, push notifications, in-app purchases, and social media integration, to enhance user engagement and drive business growth.</p>

            <p>Throughout the development cycle, we maintain regular communication and provide updates to keep you involved and informed. Our dedication to
            delivering on-time and within budget ensures that your custom mobile app is ready to make an impact in the hands of your target audience.</p>

            <p>Partner with us for custom mobile app development that is tailored to your specific business needs, providing a seamless user experience
              and driving your business forward in the digital age.</p>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default MobileAppDev