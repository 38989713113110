import React from 'react';
import Classes from './HeroSection.module.css';

function HeroSection(props) {
    return (
        <div className={Classes.small_hero_section}>

            <div className={Classes.small_hero_left_section}>
                <div className={Classes.small_hero_left_content_section}>
                    <h1>{props?.text}</h1>
                </div>
            </div>
            <div className={Classes.small_hero_right_section}>
                <img src={props?.image} alt="" />
            </div>
        </div>

    )
}

export default HeroSection