import React from 'react'
import './AboutUs.css';
import about_feature_img from '../../images/about_feature_img.png';
import about_hero_img from '../../images/aboutus_hero_img.png'
import { BsCheck } from 'react-icons/bs';
import Utils from '../../libs/Utils';
import { useEffect } from 'react';
function AboutUs() {
    useEffect(() => {
        Utils.setPageTitle("About neXiate: Empowering Businesses through Innovative Digital Solutions");
        Utils.setMetaTag("Learn about neXiate, a trusted provider of cutting-edge digital solutions. With a dedicated team of experts and a passion for excellence, we help businesses thrive in the digital landscape through custom web and mobile app development, cloud integration, and comprehensive support services.")
      }, [])

    
    return (
        <>
         
            <div className="aboutus_wrapper">
                <div className="small_hero_section">
                    <div className="small_hero_left_section">
                        <div className="small_hero_left_content_section">
                            <h1>Few words about our crew</h1>
                        </div>
                    </div>
                    <div className="small_hero_right_section">
                        <img src={about_hero_img} alt="" />
                    </div>
                </div>
                <div className="about_us_content_section">
                    <div className="about_us_content_area">
                        <div className="our_mission_section">
                            <div className="inner_sec_container">
                                <h2>We at Nexiate are committed to delivering maximum value to our clients, helping them succeed in a constantly changing and challenging business world</h2>
                                <p>Our fundamental company values enable us to perform our mission in the best way possible:</p>
                                <div className="our_mission_area">
                                    <BsCheck className='check_icon' />
                                    <span>Professional approach</span>
                                    <BsCheck className='check_icon' />
                                    <span>Innovation and creativity</span>
                                    <BsCheck className='check_icon' />
                                    <span>Win-win cooperation with clients and partners</span>
                                    <BsCheck className='check_icon' />
                                    <span>Effective client communication</span>
                                    <BsCheck className='check_icon' />
                                    <span>Reliability and Trust</span>
                                </div>
                            </div>
                            <div className="about_features_section mt-3">
                                <div className='inner_sec_container'>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="about_features_left_section">
                                                <img src={about_feature_img} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="about_features_right_section">
                                                <div className='about_features_right_area'>
                                                    <h2>We have credible and accountable approach for a rapid project accomplishment of any size – ranging from small apps to complex software systems.</h2>
                                                    <div className="our_mission_area mt-4">
                                                        <BsCheck className='check_icon' />
                                                        <span>Praesent magna tincidunt</span>
                                                        <BsCheck className='check_icon' />
                                                        <span>Cum sociis penatibus et</span>
                                                        <BsCheck className='check_icon' />
                                                        <span>Morbi commodo cras congue</span>
                                                        <BsCheck className='check_icon' />
                                                        <span>Pellentesque suscipit et</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutUs