import React, { useEffect } from 'react';
import './Services.css';
import HeroSection from '../../Component/SmallHeroSection/HeroSection';
import { Link, useNavigate } from 'react-router-dom';
import Quote_Now_img from '../../images/quote_now_img.png'
import service_hero_img from '../../images/services_hero_img.png';
import mobileAppDevImg from '../../images/mobile-app-developing-svgrepo-com.svg'
import ResponsiveWebDEv from '../../images/responsive-web-development.svg'
import Ecommerce from '../../images/Ecommerce.svg'
import CrossPlatform from '../../images/cross-platform-dev.svg'
import WebMobileUIUX from '../../images/web-mobile-ui-ux.svg'
import CloudIntegration from '../../images/cloud-integration-migration.svg'
import MaintainceSupport from '../../images/maintaince-support.svg'
import DedicatedResource from '../../images/dedicated-resources.svg'
import CallCenter from '../../images/call-center.svg'
import AdditionalService from '../../images/additional-services.svg'
import Utils from '../../libs/Utils';

function Services() {
  const history = useNavigate();
  const gotoQuote = () => {
    history('/contact-us');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  function handleLinkClick() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  useEffect(() => {
    Utils.setPageTitle("Comprehensive Digital Solutions: Services by neXiate");
    Utils.setMetaTag("Explore neXiate's wide range of comprehensive digital solutions tailored to meet your business needs. From custom web and mobile app development to cloud integration and maintenance, our expert team is committed to delivering exceptional results that drive growth and success for your business.")
  }, [])
  return (
    <>
      <div className="services_wrapper">
        <div className="small_hero_section">
          <div className="small_hero_left_section">
            <div className="small_hero_left_content_section">
              <h1>A wide range of services</h1>
            </div>
          </div>
          <div className="small_hero_right_section">
            <img src={service_hero_img} alt="" />
          </div>
        </div>
        <div className="services_content_section">
          <div className="inner_sec_container">
            <div className="services_content_area">
              <h2>We are committed to delivering high-quality solutions that drive business growth and exceed client expectations. </h2>
              <p>Our team works closely with you to understand your goals and provide customized services that align with your vision. <Link to="/contact-us" className='contact_link'>Contact us</Link> today to discuss how we can help you achieve your digital objectives.</p>

              <div className="services_card_section">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <Link to="/mobile-app-development" onClick={handleLinkClick}>
                      <div className="services_card_area">
                        <div className="services_icon_section">
                          <img src={mobileAppDevImg} alt="img" />
                        </div>
                        <div className="services_card_content_section">
                          <div className="services_card_content_area">
                            <h3>Mobile App Development</h3>
                            <p>Create tailored mobile applications for iOS and Android platforms, designed to meet specific client requirements and deliver seamless user experiences.</p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <Link to="/responsive-website" onClick={handleLinkClick}>
                      <div className="services_card_area">
                        <div className="services_icon_section">
                          <img src={ResponsiveWebDEv} alt="img" />
                        </div>
                        <div className="services_card_content_section">
                          <div className="services_card_content_area">
                            <h3>Responsive Web Development</h3>
                            <p>Develop responsive websites and web pplications that adapt to different screen sizes and devices, ensuring optimal user experience across desktop, mobile, and tablet platforms.</p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <Link to="/ecommerce-solution" onClick={handleLinkClick}>
                      <div className="services_card_area">
                        <div className="services_icon_section">
                          <img src={Ecommerce} alt="img" />
                        </div>
                        <div className="services_card_content_section">
                          <div className="services_card_content_area">
                            <h3>E-commerce Solutions</h3>
                            <p>Build secure and user-friendly e-commerce platforms for clients to establish their online stores, integrating payment gateways, inventory management, and customer engagement features.</p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <Link to="/cross-platform-development" onClick={handleLinkClick}>
                      <div className="services_card_area">
                        <div className="services_icon_section">
                          <img src={CrossPlatform} alt="img" />
                        </div>
                        <div className="services_card_content_section">
                          <div className="services_card_content_area">
                            <h3>Cross-Platform Development</h3>
                            <p>Offer cross-platform development services using frameworks like React Native or Flutter, allowing clients to reach a wider audience with a single codebase.</p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <Link to="/web-mobile-ui-ux-design" onClick={handleLinkClick}>
                      <div className="services_card_area">
                        <div className="services_icon_section">
                          <img src={WebMobileUIUX} alt="img" />
                        </div>
                        <div className="services_card_content_section">
                          <div className="services_card_content_area">
                            <h3>Web and Mobile UI/UX Design</h3>
                            <p>Create visually appealing and intuitive user interfaces (UI) and deliver exceptional user experiences (UX) for web and mobile applications.</p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <Link to="/cloud-integration-and-migration" onClick={handleLinkClick}>
                      <div className="services_card_area">
                        <div className="services_icon_section">
                          <img src={CloudIntegration} alt="img" />
                        </div>
                        <div className="services_card_content_section">
                          <div className="services_card_content_area">
                            <h3>Cloud Integration and Migration</h3>
                            <p>Help clients leverage the benefits of cloud computing by integrating their applications with leading cloud platforms such as AWS, Azure, or Google Cloud, and facilitate seamless migration to the cloud.</p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <Link to="/maintenance-and-support" onClick={handleLinkClick}>
                      <div className="services_card_area">
                        <div className="services_icon_section">
                          <img src={MaintainceSupport} alt="img" />
                        </div>
                        <div className="services_card_content_section">
                          <div className="services_card_content_area">
                            <h3>Maintenance and Support</h3>
                            <p>Offer ongoing maintenance and support services to ensure the smooth operation, performance optimization, and timely updates of the developed applications.</p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <Link to="/dedicated-resource-teams" onClick={handleLinkClick}>
                      <div className="services_card_area">
                        <div className="services_icon_section">
                          <img src={DedicatedResource} alt="img" />
                        </div>
                        <div className="services_card_content_section">
                          <div className="services_card_content_area">
                            <h3>Dedicated Resource Teams</h3>
                            <p>Provide dedicated resources and teams to clients, working full-time in their respective time zones. This ensures real-time collaboration, effective communication, and seamless coordination between your team and the client, resulting in efficient project management and faster time-to-market.</p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <Link to="/call-center-services" onClick={handleLinkClick}>
                      <div className="services_card_area">
                        <div className="services_icon_section">
                          <img src={CallCenter} alt="img" />
                        </div>
                        <div className="services_card_content_section">
                          <div className="services_card_content_area">
                            <h3>Call Center Services</h3>
                            <p>Deliver exceptional customer support and enhance customer experiences with our call center services. Our trained professionals handle inbound and outbound calls, providing personalized assistance and ensuring customer satisfaction.</p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <Link to="/additional-services" onClick={handleLinkClick}>
                      <div className="services_card_area">
                        <div className="services_icon_section">
                          <img src={AdditionalService} alt="img" />
                        </div>
                        <div className="services_card_content_section">
                          <div className="services_card_content_area">
                            <h3>Additional Services</h3>
                            <p>In addition to our core services, we also offer a range of supplementary services such as content writing, digital marketing, SEO, and more. Our team of experts can help you create compelling content, improve your online visibility, and drive targeted traffic to your website.</p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="quote_now_section">
          <div className="quote_now_img_section">
            <img src={Quote_Now_img} alt="" />
          </div>
          <div className="quote_now_content_section">
            <div className="quote_now_content_area">
              <h3>Request Your Free Quote Now!</h3>
              <p>Unlock the potential of your business with a free quote tailored just for you. Click below to get started and let our experts understand your needs. Take the first step towards success today</p>
              <div className="quote_now_button_section">
                <button className='get_quote_btn' onClick={gotoQuote}>Get Quote</button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Services