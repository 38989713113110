import React from 'react'
import Classes from './Footer.module.css';
import { FaFacebookF, FaTwitter, FaLinkedinIn } from 'react-icons/fa'
import { BiUpArrowAlt } from 'react-icons/bi';
import { Link, useLocation } from 'react-router-dom';


function Footer() {
    function handleLinkClick() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    const location = useLocation();
    return (
        <footer className={Classes.footer_wrapper}>
            <div className={Classes.footer_top_section}>
                <div className={Classes.inner_sec_container}>
                    <div className={Classes.footer_content_section}>
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className={Classes.footer_content_area}>
                                    <span>Call Now</span> <br />
                                    <h2><a href='tel:+61481286345'>+61 (0) 481 286 345</a></h2>
                                    <p>Expert Solutions Just a Phone Call Away!</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3">
                                <div className={Classes.footer_content_area}>
                                    <span>OUR ADDRESS</span>
                                    <h2 className={Classes.address_heading}>AUSTRALIA</h2>
                                    <p>Sydney CBD, 2000, Australia</p>
                                    <a href="mailto:sales@nexiate.com.au" className={Classes.email_link}>sales@nexiate.com.au</a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3">
                                <div className={Classes.footer_content_area}>
                                    <span>FOLLOW US</span>
                                   
                                    <div className={Classes.footer_social_icon_section}>
                                        <a href='https://www.facebook.com/neXiate' target='_blank'><FaFacebookF className={Classes.social_icon} /></a>
                                        <a href='https://linkedin.com/company/nexiate' target='_blank'><FaLinkedinIn className={Classes.social_icon} /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
                <hr />
                    <div className={Classes.footer_bottom_content_section}>
                    <div className={Classes.inner_sec_container}>
                        <div className={`row ${Classes.row_mb}`}>
                            <div className="col-lg-6 col-md-5">
                                <div className={Classes.footer_bottom_content_left_section}>
                                    <p>© 2023 - Nexiate</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-7">
                                <div className={Classes.footer_bottom_content_right_section}>
                                <ul>
                                    <li>
                                    <Link to="/" className={location.pathname === '/' ? `${Classes.active}` : ''} onClick={handleLinkClick}>Home</Link>
                                    </li>
                                    <li>
                                    <Link to="/about-us" className={location.pathname === '/about-us' ? `${Classes.active}` : ''} onClick={handleLinkClick}>About us</Link>
                                    </li>
                                    <li>
                                    <Link to="/services" className={location.pathname === '/services' ? `${Classes.active}` : ''} onClick={handleLinkClick}>Services</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact-us" className={location.pathname === '/contact-us' ? `${Classes.active}` : ''} onClick={handleLinkClick}>Contact</Link>
                                    </li>
                                </ul>
                                <BiUpArrowAlt className={Classes.upArrow_icon} onClick={handleLinkClick}/>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
            </div>
        </footer>
    )
}

export default Footer