class Utils 
{
    static setPageTitle(title) 
    {
      document.title = title;
    }

    static setMetaTag(name, content) 
    {
      let metaTag = document.head.querySelector(`meta[name="${name}"]`);
      if (!metaTag) {
        metaTag = document.createElement("meta");
        metaTag.name = name;
        document.head.appendChild(metaTag);
      }
      metaTag.content = content;
    }
    
  }
  export default Utils;