import React from 'react'
import './servicesHero.css'
function ServicesHero(props) {
  return (
    <div className='servicesHero_section'>
        <div className='servicesHero_area'>
            <h1>{props.text}</h1>
        </div>
    </div>
  )
}

export default ServicesHero