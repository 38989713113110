import React, { useEffect } from 'react'
import ServicesHero from '../../../Component/servicesHeroSection/ServicesHero'
import ServicesCard from '../../../Component/ServicesCard/ServicesCard'
import Utils from '../../../libs/Utils'

function DedicatedResTeam() {
  useEffect(() => {
    Utils.setPageTitle("Unleash the Power of Dedicated Resource Teams with neXiate");
    Utils.setMetaTag("Experience the benefits of dedicated resource teams with neXiate. Our skilled professionals, including mobile developers, DevOps experts, product owners, and more, work tirelessly to bring your digital projects to life. With flexible engagement models and full-time commitment, we ensure real-time collaboration and efficient project management, delivering outstanding results that drive your business forward.")
  }, [])
  return (
    <>
    <div className='mobileAppDev_wrapper'>
    <ServicesHero text="Your Dedicated Team, Your Success" />
    <div className="inner_sec_container">
      <div className="mobileAppDev_section mt-5">
      <h1>By leveraging the expertise and specialization of dedicated resource teams, businesses can access a pool of talent tailored to their unique requirements. The flexibility and scalability offered by dedicated resources enable businesses to adapt to changing project needs. Additionally, seamless collaboration and communication ensure a streamlined and efficient working relationship between the client and the dedicated resource team, resulting in successful project outcomes.</h1>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12 mb-5">
            <ServicesCard color="black" heading="Expertise and Specialization" desc="Dedicated resource teams consist of highly skilled professionals who possess expertise and specialization in their respective fields. These teams are handpicked and specifically assembled to meet the unique requirements of each client. By having access to a dedicated team with specialized knowledge, businesses can benefit from their expertise and tap into their in-depth understanding of industry best practices, latest technologies, and domain-specific knowledge." />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mb-5">
            <ServicesCard color="grey" heading="Flexibility and Scalability" desc="Identifying and implementing the essential functionalities and features that align with the app's purpose and target audience is vital.This involves thorough planning and understanding of user requirements to deliver a valuable and effective mobile app solution." />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mb-5">
            <ServicesCard color="blue" heading="Seamless Collaboration" desc="Considering the different mobile platforms (such as iOS and Android) and ensuring compatibility across devices and operating systems is important. Adapting the app's design and development to each platform's guidelines and best practices ensures a consistent experience for users across various devices and operating systems." />
          </div>
        </div>
        <div className="mobileAppDev_desc_area">
          <p>At [Your Company Name], we offer dedicated resource teams with a wide range of skills and expertise to meet the unique requirements of your projects. Our teams consist of professionals specializing in various areas, including front-end development, mobile development, DevOps, product ownership, Scrum mastering, content writing, marketing, SEO, backend development, full stack development, support and maintenance, and call center services.</p>
          <h3>Front-End Developers with Expertise in Popular JavaScript Frameworks</h3>
          <p>Our dedicated front-end developers are highly skilled in HTML, CSS, and JavaScript, with expertise in popular JavaScript frameworks such as React, Angular, or Vue.js. They have a deep understanding of front-end development best practices, responsive design, and cross-browser compatibility. With their knowledge and proficiency, they create visually appealing and interactive interfaces that provide an exceptional user experience.</p>
          <h3>Backend Developers</h3>
          <p>Our dedicated backend developers specialize in server-side technologies and frameworks such as Java, Python, PHP, or Node.js. They are experienced in designing and developing robust APIs, database integration, and implementing secure and scalable server-side logic. They ensure the seamless integration of front-end and back-end components, delivering high-performance and reliable web applications.</p>
          <h3>Full Stack Developers</h3>
          <p>Our dedicated full stack developers are proficient in both front-end and back-end technologies. They have a comprehensive understanding of the entire development stack, allowing them to handle end-to-end development tasks efficiently. They can seamlessly switch between front-end and back-end development, ensuring a cohesive and integrated approach to building web applications.</p>
          <h3>Mobile Developers</h3>
          <p>Our dedicated mobile developers have extensive experience in developing mobile applications for iOS and Android platforms. They are proficient in languages such as Swift and Kotlin, and frameworks like React Native or Flutter. They ensure that your mobile app is robust, user-friendly, and optimized for performance across different devices.</p>
          <h3>DevOps</h3>
          <p>Our DevOps experts help streamline your development and deployment processes, ensuring efficient collaboration between development and operations teams. They are skilled in implementing automation, continuous integration and deployment, monitoring, and infrastructure management, enabling faster and more reliable software delivery.</p>
          <h3>Product Owners and Scrum Masters</h3>
          <p>Our dedicated product owners and Scrum masters bring a deep understanding of agile methodologies and product management. They ensure that your projects are aligned with your business objectives, manage requirements effectively, and facilitate smooth project execution. Their expertise enables efficient team coordination and effective prioritization of tasks.</p>
          <h3>Content Writers and Marketing Specialists</h3>
          <p>Our dedicated content writers and marketing specialists create compelling and engaging content for your website, blogs, social media, and other marketing channels. They have a strong understanding of digital marketing strategies, SEO best practices, and audience targeting. They help you communicate your brand message effectively and drive user engagement and conversions.</p>
          <h3>SEO Gurus</h3>
          <p>Our dedicated SEO gurus have a thorough understanding of search engine optimization techniques and practices. They conduct in-depth keyword research, optimize website content, and implement SEO strategies to improve your website's visibility and rankings in search engine results. They help drive organic traffic and enhance your online presence.</p>
          <h3>Support and Maintenance</h3>
          <p>We offer dedicated support and maintenance services to ensure the smooth operation and ongoing performance of your applications. Our team provides timely bug fixes, software updates, and enhancements to address any issues that may arise. We prioritize your business continuity and provide reliable support to keep your systems running optimally.</p>
          <h3>Call Center Services</h3>
          <p>Our call center services enable you to provide exceptional customer support and handle customer inquiries effectively. Our dedicated call center team is trained to deliver professional and efficient assistance, ensuring customer satisfaction. We can handle inbound and outbound calls, providing a personalized experience to your customers.</p>
          <p>By partnering with our dedicated resource teams, you gain access to a diverse range of skills and expertise. Our professionals are committed to delivering high-quality solutions</p>
        </div>
      </div>
    </div>
  </div>
  </>
  )
}

export default DedicatedResTeam