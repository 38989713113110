import React, { useEffect } from 'react'
import ServicesHero from '../../../Component/servicesHeroSection/ServicesHero'
import ServicesCard from '../../../Component/ServicesCard/ServicesCard'
import Utils from '../../../libs/Utils';

function CrossPlatformDev() {
  useEffect(() => {
    Utils.setPageTitle("Reach a Wider Audience: Cross-Platform Development by neXiate");
    Utils.setMetaTag("Expand your reach with neXiate's cross-platform development services. Our skilled team builds high-performance apps that work seamlessly across multiple platforms, including iOS, Android, and web. With a unified codebase and consistent user experiences, we help you engage a broader audience and maximize your app's potential.")
  }, [])
  return (
    <>
    <div className='mobileAppDev_wrapper'>
    <ServicesHero text="Reaching Wider Audiences" />
    <div className="inner_sec_container">
      <div className="mobileAppDev_section mt-5">
      <h1>By emphasizing code reusability, platform consistency, and time and cost efficiency, cross-platform development enables businesses to maximize their resources, streamline development processes, and deliver high-quality applications to users across multiple platforms.</h1>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12 mb-5">
            <ServicesCard color="blue" heading="Code Reusability" desc="Cross-platform development allows for the efficient reuse of code across multiple platforms, such as iOS, Android, and web. This eliminates the need to develop separate codebases for each platform, resulting in cost savings, faster development cycles, and easier maintenance." />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mb-5">
            <ServicesCard color="grey" heading="Platform Consistency" desc="Cross-platform development ensures consistent user experiences across different platforms. By utilizing frameworks and tools that support platform-specific UI elements and functionality, developers can create apps that look and feel native on each platform, providing a seamless and familiar experience for users." />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mb-5">
            <ServicesCard color="blue" heading="Time and Cost Efficiency" desc="Developing a single codebase that can be deployed across multiple platforms significantly reduces development time and costs. Instead of building separate apps for each platform, developers can focus on a unified codebase, accelerating the development process and enabling businesses to reach a wider audience more quickly." />
          </div>
        </div>
        <div className="mobileAppDev_desc_area">
          <p>At neXiate, we specialize in cross-platform development, offering comprehensive solutions that enable businesses to reach a wider audience and maximize their app's potential. With the increasing demand for mobile apps across various platforms, it has become crucial for businesses to adopt cross-platform development strategies to optimize their resources and streamline their app development processes.</p>
          <h3>Code Reusability and Efficiency</h3>
          <p>Our cross-platform development approach focuses on code reusability, allowing developers to write a single codebase that can be deployed across multiple platforms, including iOS, Android, and web. By leveraging frameworks and tools such as React Native, Flutter, or Xamarin, we ensure efficient development cycles, reduced development time, and cost savings. This approach eliminates the need to develop separate codebases for each platform, enabling businesses to launch their apps faster while maintaining consistent functionality and user experience.</p>
          <h3>Native-Like User Experience</h3>
          <p>We understand the importance of delivering a native-like experience for users on each platform. Our cross-platform development solutions leverage the capabilities of platform-specific UI elements and APIs, ensuring that the app looks and feels native on every device. By tailoring the user interface and user experience to each platform's standards and guidelines, we create apps that provide a seamless and familiar experience, enhancing user satisfaction and engagement.</p>
          <h3>Cost-Effectiveness and Time Efficiency</h3>
          <p>Cross-platform development offers significant cost and time savings compared to developing separate apps for each platform. With a unified codebase, businesses can allocate their resources more efficiently, reducing development and maintenance costs. Moreover, updates and bug fixes can be deployed simultaneously across all platforms, eliminating the need for separate releases and reducing time-to-market.</p>
          <p>Partner with us for cross-platform development solutions that empower your business to expand its reach, optimize resources, and deliver high-quality apps across multiple platforms. Our experienced team of developers will work closely with you to understand your requirements, ensure seamless integration with platform-specific features, and deliver a cross-platform app that exceeds your expectations. Let us help you leverage the power of cross-platform development to drive your business forward in the rapidly evolving digital landscape.</p>
        </div>
      </div>
    </div>
  </div>
  </>
  )
}

export default CrossPlatformDev